import { useState } from "react";

import { Input, message } from "antd";

import checkIcon from "data/icons/check.svg";
import closeIcon from "data/icons/close.svg";
import plusIcon from "data/icons/plus.svg";
import editIcon from "data/icons/edit.svg";
import { IShortContract } from "store/contracts/contracts.types";
import api from "services/api.service";

import "./ContractAlias.scss";

interface IContractAliasProps {
	item: IShortContract;
}

const ContractAlias = ({ item }: IContractAliasProps) => {
	const [ isEditingAlias, setIsEditingAlias ] = useState(false);

	const [ alias, setAlias ] = useState(item.meta.alias);
	const [ prevAlias, setPrevAlias ] = useState(item.meta.alias);

	const handleAlias = async () => {
		try {
			await api.setContractAlias(alias, item.id);
		} catch (e: any) {
			message.error(e.response.data.error || e.error || "Something went wrong!");
		}
	};

	const onEdit = () => {
		setPrevAlias(alias);
		setIsEditingAlias(true);
	};

	const onComplete = () => {
		handleAlias();
		setPrevAlias(alias);
		setIsEditingAlias(false);
	};

	const onCancel = () => {
		setIsEditingAlias(false);
		setAlias(prevAlias);
	};

	if (isEditingAlias) {
		return (
			<Input
				className="alias-input"
				value={ alias }
				onChange={ (e) => setAlias(e.target.value) }
				suffix={ (
					<>
						<img
							alt=""
							height={ 14 }
							width={ 14 }
							src={ checkIcon }
							onClick={ onComplete }
						/>

						<img
							alt=""
							onClick={ onCancel }
							src={ closeIcon }
						/>
					</>
				) }
				onClick={ (e) => e.stopPropagation() }
			/>
		);
	}

	return (
		<div className="alias-wrapper">
			{alias === "" ? (
				<div
					className="add-title"
					onClick={ onEdit }
				>
					<img alt="" className="edit-button" src={ plusIcon } />
					Add
				</div>
			) : (
				<>
					<p
						className={ `alias-value ${ item?.is_active ? "alias-active" : "" }` }
						onClick={ onEdit }
					>
						{alias}

						<img
							onClick={ onEdit }
							className="edit-button"
							src={ editIcon }
							alt=""
						/>
					</p>
				</>
			)}
		</div>
	);
};

export default ContractAlias;
