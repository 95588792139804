import hydraAccountBlue from "data/icons/hydra-account-blue.svg";
import hydraAccount from "data/icons/hydra-account.svg";

import "./CustomerSection.scss";

interface ICustomerSectionProps {
	label: string;
	hasAccount: boolean;
	value: string;
}

const CustomerSection = ({ label, hasAccount, value }: ICustomerSectionProps) => {
	return (
		<div className="customer-section">
			<div className="customer-section-title">
				{label}
			</div>

			<div className="customer-section-info">
				<img
					alt=""
					src={ hasAccount ? hydraAccountBlue : hydraAccount }
				/>

				{value}
			</div>
		</div>
	);
};

export default CustomerSection;
