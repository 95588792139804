import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";

import expired from "data/icons/expired.svg";
import pending from "data/icons/pending.svg";
import checkGreen from "data/icons/check-green.svg";
import { CompanyItem } from "components/common";
import { IShortContract } from "store/contracts/contracts.types";
import handshakeIcon from "data/icons/handshake.svg";
import handshakeBlueIcon from "data/icons/handshake-blue.svg";
import { RouteLinks } from "services/router.service";
import { ContractAlias } from "components/contracts";
import "components/cases/CasesTable/CasesTable.scss";

const statusIcons = {
	Active: checkGreen,
	Draft: pending,
	Pending: pending,
	Expired: expired,
};

interface IEquipmentContractsProps {
	contracts: IShortContract[];
}

const EquipmentContracts = ({ contracts }: IEquipmentContractsProps) => {
	const navigate = useNavigate();

	const handleOpenContract = (contractId: number) => {
		navigate(RouteLinks.PROTECTED.CONTRACTS_ROUTES.CONTRACT + "/" + contractId);
	};

	const columns: ColumnsType<IShortContract> = [
		{
			title: "Status",
			dataIndex: "status_value",
			key: "status_value",
			width: 80,
			render: (value, item) => (
				<div style={ { display: "flex", gap: 20, alignItems: "center" } }>
					<Tooltip title={ value }>
						<img
							alt=""
							// @ts-ignore
							src={ statusIcons[ value ] }
						/>
					</Tooltip>

					{item.handshake_completed ? (
						<Tooltip title="Handshaked">
							<img src={ handshakeBlueIcon } alt="" />
						</Tooltip>
					) : (
						<Tooltip title="Not handshaked">
							<img src={ handshakeIcon } alt="" />
						</Tooltip>
					)}
				</div>
			),
		},
		{
			title: "Contract Number",
			dataIndex: "identifier",
			key: "identifier",
			className: "contract-number-table-cell",
			render: (value, item) =>
				<p
					className={ item.is_active ? "active" : "" }
					onClick={ () => handleOpenContract(item.id) }
				>
					{value}
				</p>,
			width: 180,
		},
		{
			title: "Organisation",
			dataIndex: "customer",
			key: "customer",
			className: `description-table-cell`,
			render: (value, item) => (
				<CompanyItem
					email={ item.contractor.default_email }
					has_account={ item.contractor.has_account }
					has_verified_account={ item.contractor.has_verified_account }
					official_name={ item.contractor.official_name }
					phone={ item.contractor.phone_number }
					value={ item.contractor.name }
				/>
			),
		},
		{
			title: "Alias",
			dataIndex: "alias",
			key: "alias",
			width: 350,
			render: (value, item) => (
				<ContractAlias item={ item } />
			),
		},
		{
			title: "Start date",
			dataIndex: "date_begin",
			key: "date_begin",
			width: 140,
			onCell: () => ({
				style: { minWidth: "140px" },
			}),
		},
		{
			title: "End date",
			dataIndex: "date_end",
			key: "date_end",
			width: 140,
			onCell: () => ({
				style: { minWidth: "140px" },
			}),
		},
	];

	return (
		<Table
			className="cases-table contracts-table"
			columns={ columns }
			dataSource={ contracts }
			pagination={ false }
			tableLayout="auto"
			rowKey={ (item) => item.id }
			scroll={ { y: window.innerHeight - 183 - (document.getElementById("contracts-header-cell")?.offsetHeight || 0) } }
		/>
	)
}

export default EquipmentContracts;
