import { FC, useEffect, useState } from "react";

import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";

import expired from "data/icons/expired.svg";
import pending from "data/icons/pending.svg";
import checkGreen from "data/icons/check-green.svg";
import { Loading ,CompanyItem } from "components/common";
import { useAppDispatch, useAppSelector } from "store";
import { getContractsSelector } from "store/contracts/contracts.selector";
import { IShortContract } from "store/contracts/contracts.types";
import { isFuture } from "services/data.service";
import grayDotIcon from "data/icons/dot-gray.svg";
import handshakeIcon from "data/icons/handshake.svg";
import exclamationIconOrange from "data/icons/exclamation-orange.svg";
import handshakeBlueIcon from "data/icons/handshake-blue.svg";
import "components/cases/CasesTable/CasesTable.scss";
import { RouteLinks } from "services/router.service";
import { updateContractsSort } from "store/contracts/contracts.actions";
import { getContracts } from "store/contracts/contracts.thunks";
import { ContractAlias } from "components/contracts";

import "./ContractsTable.scss";

const statusIcons = {
	Active: checkGreen,
	Draft: pending,
	Pending: pending,
	Expired: expired,
};

const ContractsTable: FC = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { isContractsLoading, contracts, sort } = useAppSelector(getContractsSelector);

	const [ updateState, setUpdateState ] = useState(false);

	useEffect(() => setUpdateState(!updateState), [ isContractsLoading ]);

	const columns: ColumnsType<IShortContract> = [
		{
			title: "Status",
			dataIndex: "status_value",
			key: "status_value",
			width: 80,
			render: (value, item) => (
				<div style={ { display: "flex", gap: 20, alignItems: "center" } }>
					<Tooltip title={ item.warning_reason || value }>
						<img
							alt=""
							className={ `dot-prefix ${ item.warning_reason !== null && "warning" }` }
							src={
								item.warning_reason === null
									? !isFuture(item.date_end)
										// @ts-ignore
										? statusIcons[ value ]
										: grayDotIcon
									: exclamationIconOrange }
						/>
					</Tooltip>

					{item.handshake_completed ? (
						<Tooltip title="Handshaked">
							<img src={ handshakeBlueIcon } alt="" />
						</Tooltip>
					) : (
						<Tooltip title="Not handshaked">
							<img src={ handshakeIcon } alt="" />
						</Tooltip>
					)}
				</div>
			),
		},
		{
			title: "Contract Number",
			dataIndex: "identifier",
			key: "identifier",
			className: "contract-number-table-cell",
			render: (value, item) =>
				<p
					className={ item.is_active ? "active" : "" }
					onClick={ () => handleOpenContract(item.id) }
				>
					{value}
				</p>,
			sortOrder: sort.column === "identifier" ? sort.direction : undefined,
			sorter: sort.column === "identifier",
			showSorterTooltip: false,
			width: 180,
			onHeaderCell: () => ({
				id: "contracts-header-cell",
				onClick: () => {
					dispatch(updateContractsSort({
						column: "identifier",
						direction: sort.column === "identifier" ? sort.direction === "ascend" ? "descend" : "ascend" : "ascend",
					}));
					dispatch(getContracts());
				},
			}),
		},
		{
			title: "Organisation",
			dataIndex: "customer",
			key: "customer",
			sortOrder: sort.column === "customer" ? sort.direction : undefined,
			sorter: sort.column === "customer",
			showSorterTooltip: false,
			className: `description-table-cell`,
			render: (value, item) => (
				<CompanyItem
					email={ item.contractor.default_email }
					has_account={ item.contractor.has_account }
					has_verified_account={ item.contractor.has_verified_account }
					official_name={ item.contractor.official_name }
					phone={ item.contractor.phone_number }
					value={ value }
				/>
			),
			onHeaderCell: () => ({
				onClick: () => {
					dispatch(updateContractsSort({
						column: "customer",
						direction: sort.column === "customer" ? sort.direction === "ascend" ? "descend" : "ascend" : "ascend",
					}))
					dispatch(getContracts())
				},
			}),
		},
		{
			title: "Alias",
			dataIndex: "alias",
			key: "alias",
			width: 350,
			render: (value, item) => (
				<ContractAlias item={ item } />
			),
			sortOrder: sort.column === "alias" ? sort.direction : undefined,
			sorter: sort.column === "alias",
			showSorterTooltip: false,
			onHeaderCell: () => ({
				onClick: () => {
					dispatch(updateContractsSort({
						column: "alias",
						direction: sort.column === "alias" ? sort.direction === "ascend" ? "descend" : "ascend" : "ascend",
					}))
					dispatch(getContracts())
				},
			}),
		},
		{
			title: "Start date",
			dataIndex: "date_begin",
			key: "date_begin",
			sortOrder: sort.column === "date_begin" ? sort.direction : undefined,
			sorter: sort.column === "date_begin",
			showSorterTooltip: false,
			width: 140,
			onHeaderCell: () => ({
				onClick: () => {
					dispatch(updateContractsSort({
						column: "date_begin",
						direction: sort.column === "date_begin" ? sort.direction === "ascend" ? "descend" : "ascend" : "ascend",
					}));
					dispatch(getContracts());
				},
			}),
			onCell: () => ({
				style: { minWidth: "140px" },
			}),
		},
		{
			title: "End date",
			dataIndex: "date_end",
			key: "date_end",
			width: 140,
			render: (value, item) => <p className={ item.warning_reason === null ? "" : "warning" }>{value}</p>,
			sortOrder: sort.column === "date_end" ? sort.direction : undefined,
			sorter: sort.column === "date_end",
			showSorterTooltip: false,
			onHeaderCell: () => ({
				onClick: () => {
					dispatch(updateContractsSort({
						column: "date_end",
						direction: sort.column === "date_end" ? sort.direction === "ascend" ? "descend" : "ascend" : "ascend",
					}))
					dispatch(getContracts())
				},
			}),
			onCell: () => ({
				style: { minWidth: "140px" },
			}),
		},
	];

	const handleOpenContract = (contractId: number) => {
		navigate(RouteLinks.PROTECTED.CONTRACTS_ROUTES.CONTRACT + "/" + contractId)
	};

	return (
		<Loading
			isLoading={ isContractsLoading }
			isColored
			isFullWidth
			height={ 200 }
		>
			<Table
				className="cases-table contracts-table"
				columns={ columns }
				dataSource={ contracts }
				pagination={ false }
				tableLayout="auto"
				rowKey={ (item) => item.id }
				scroll={ { y: window.innerHeight - 183 - (document.getElementById("contracts-header-cell")?.offsetHeight || 0) } }
			/>
		</Loading>
	)
}

export default ContractsTable;
