import { ReactNode } from "react";

import "./InfoRow.scss";

interface IInfoRowProps {
	label: string;
	children: ReactNode;
}

const InfoRow = ({ label, children }: IInfoRowProps) => {
	return (
		<div className="info-row">
			<div className="info-row-title">
				{label}
			</div>

			{children}
		</div>
	);
};

export default InfoRow;
