import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { message } from "antd";

import { IEquipmentSource } from "store/listEquipment/listEquipment.types";
import { ICase } from "store/cases/cases.types";
import { IShortContract } from "store/contracts/contracts.types";
import api from "services/api.service";

export const useCurrentEquipment = () => {
	const { equipmentId } = useParams();

	const [ equipment, setEquipment ] = useState<IEquipmentSource>();
	const [ activeTab, setActiveTab ] = useState<"cases" | "contracts">("cases");

	const [ cases, setCases ] = useState<ICase[]>([]);
	const [ contracts, setContracts ] = useState<IShortContract[]>([]);

	const getCurrentEquipment = async (equipmentId: string) => {
		try {
			const response = await api.getEquipment(equipmentId);

			setEquipment(response.data);
		} catch (e) {
			message.error("Something went wrong!");
		}
	};

	const getEquipmentCases = async () => {
		if (equipmentId) {
			try {
				const response = await api.getEquipmentCases(equipmentId);

				setCases(response.data.results);
				setContracts([]);
			} catch (error) {
				message.error("Something went wrong!");
			}
		}
	};

	const getEquipmentContracts = async () => {
		if (equipmentId) {
			try {
				const response = await api.getEquipmentContracts(equipmentId);

				setContracts(response.data.results);
				setCases([]);
			} catch (error) {
				message.error("Something went wrong!");
			}
		}
	};

	const onTabChange = async (value: string) => {
		setActiveTab(value as "cases" | "contracts");

		if (value === "cases") {
			await getEquipmentCases();
		} else {
			await getEquipmentContracts();
		}
	};

	useEffect(() => {
		equipmentId && getCurrentEquipment(equipmentId);
		getEquipmentCases();
	}, []);

	return {
		equipment,
		activeTab,
		cases,
		contracts,
		onTabChange,
	};
};
