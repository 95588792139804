import { Tooltip } from "antd";

import { IShortContract } from "store/contracts/contracts.types";
import { StatusItem } from "components/common";
import { ContractAlias } from "components/contracts";
import handshakeBlueIcon from "data/icons/handshake-blue.svg";
import handshakeIcon from "data/icons/handshake.svg";
import hydraAccountBlue from "data/icons/hydra-account-blue.svg";
import hydraAccount from "data/icons/hydra-account.svg";

import "./ContractItemMobile.scss";

interface IContractItemMobileProps {
	item: IShortContract;
}

const ContractItemMobile = ({ item }: IContractItemMobileProps) => {
	return (
		<div className="contract-item-mobile">
			<div className="contract-item-mobile-title">
				<StatusItem
					is_active={ item.is_active }
					is_pending={ false }
				/>

				{item.handshake_completed ? (
					<Tooltip title="Handshaked">
						<img src={ handshakeBlueIcon } alt="" />
					</Tooltip>
				) : (
					<Tooltip title="Not handshaked">
						<img src={ handshakeIcon } alt="" />
					</Tooltip>
				)}

				<span className="contract-item-mobile-title-text">
					{item.identifier}
				</span>
			</div>

			<ContractAlias item={ item } />

			<div className="contract-item-mobile-footer">
				{item.contractor.has_account ? (
					<Tooltip title="Has account">
						<img src={ hydraAccountBlue } alt="" />
					</Tooltip>
				) : (
					<Tooltip title="No account">
						<img src={ hydraAccount } alt="" />
					</Tooltip>
				)}

				<span>
					{item.contractor.official_name}
				</span>
			</div>
		</div>
	);
};

export default ContractItemMobile;
