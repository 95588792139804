import { Table, Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";

import { ICase } from "store/cases/cases.types";
import { getSeverityTitle } from "services/title.service";
import { useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";
import { formatDateForApi } from "services/data.service";
import { RouteLinks } from "services/router.service";
import CompanyItem from "components/common/CompanyItem";

interface IEquipmentCasesProps {
	cases: ICase[];
}

const EquipmentCases = ({ cases }: IEquipmentCasesProps) => {
	const { isAdmin } = useAppSelector(getUserSelector);

	const columns: ColumnsType<ICase> = [
		{
			title: "Case",
			dataIndex: "custom_id",
			key: "custom_id",
			render: (value, item) => <p className={ item.status === "open" ? "active" : "" }>{ value }</p>,
			width: 170,
		},
		{
			title: "Subject",
			dataIndex: "subject",
			key: "subject",
			className: "subject-cell",
			render: (value, item) => (
				<Link
					to={ RouteLinks.PROTECTED.CASES.CASE + "/" + item.id }
					rel="noopener noreferrer"
					className={ item.status === "open" ? "active" : "" }
				>
					{ value }
				</Link>
			),
		},
		{
			title: "Opening Date",
			dataIndex: "created_at",
			key: "created_at",
			width: 140,
			render: (value) => formatDateForApi(value),
			onCell: () => ({
				style: { minWidth: "140px" },
			}),
		},
		{
			title: "Severity",
			dataIndex: "severity",
			key: "severity",
			render: (value, item) => <p className={ `severity ${ item.status === "open" ? "active" : "" } ${ value }` }>{ getSeverityTitle(value) }</p>,
			width: 130,
		},
		{
			title: "Support Type",
			dataIndex: "support_type",
			key: "support_type",
			render: (value, item) => (
				item.sla_ci ? (
					<Tooltip title={ item.sla_ci?.name }>
						<div style={ { display: "flex" } }>
							<Tag
								style={ {
									background: item.sla_ci.marker_bg_color,
									color: item.sla_ci.marker_text_color,
								} }
							>
								{ item.sla_ci?.marker }
							</Tag>
						</div>
					</Tooltip>
				) : <div style={ { textAlign: "center", width: "70px" } }>{"-//-"}</div>
			),
			width: 150,
		},
		{
			title: "Closing Date",
			dataIndex: "closed_at",
			key: "closed_at",
			render: (value) => <p className={ value === null ? "align-center" : "" }>{ value === null ? "—" : formatDateForApi(value) }</p>,
			width: 140,
			onCell: () => ({
				style: { minWidth: "140px" },
			}),
		},
		{
			title: "Organisation",
			dataIndex: "customer",
			key: "customer",
			width: 120,
			className: `customer-table-cell`,
			render: (value, item) => item.contractor ? (
				<CompanyItem
					email={ item.contractor.default_email }
					has_account={ item.contractor.has_account }
					has_verified_account={ item.contractor.has_verified_account }
					official_name={ item.contractor.official_name }
					phone={ item.contractor.phone_number }
					value={ item.contractor.name }
				/>
			) : "-/-",
		},
	]

	return (
		<Table
			className="cases-table"
			columns={ columns.filter((col) => col.key !== (isAdmin ? "severity" : "customer")) }
			dataSource={ cases }
			rowKey={ (item) => item.id }
			pagination={ false }
			scroll={ { y: window.innerHeight - 164 - (document.getElementById("cases-header-cell")?.offsetHeight || 0) - (document.getElementById("cases-filter")?.offsetHeight || 0) } }
			tableLayout="auto"
		/>
	)
}

export default EquipmentCases;
