import { useContext, useEffect } from "react";

import { Tabs, TabsProps, Tag, Tooltip } from "antd";

import {
	CustomerSection,
	InfoRow,
	Section,
	StatusItem,
} from "components/common";
import { useCurrentEquipment } from "helpers/useCurrentEquipment";
import { RouteLinks } from "services/router.service";
import { LayoutContext } from "context/LayoutContext";
import { EquipmentCases, EquipmentContracts } from "components/equipment";

import "./CurrentEquipmentPage.scss";

const tabs: TabsProps[ "items" ] = [
	{
		key: "cases",
		label: "Cases",
	},
	{
		key: "contracts",
		label: "Contracts",
	},
];

const CurrentEquipmentPage = () => {
	const { setBreadcrumbs } = useContext(LayoutContext);

	const {
		equipment,
		activeTab,
		cases,
		contracts,
		onTabChange,
	} = useCurrentEquipment();

	useEffect(() => {
		setBreadcrumbs([
			{ url: RouteLinks.PROTECTED.LIST_EQUIPMENT, label: "List equipment" },
			{ label: `${ equipment?.serial_number || "Loading..." }` },
		]);

		return () => {
			setBreadcrumbs([]);
		}
	}, [ equipment ]);

	if (!equipment) {
		return null;
	}

	return (
		<div className="default-page-wrapper">
			<Section type="info">
				<div className="current-equipment">
					<div className="current-equipment-column">
						<InfoRow label="Serial number">
							<div className="current-equipment-title">
								{equipment.serial_number}
							</div>
						</InfoRow>

						<InfoRow label="Product number">
							{equipment.product.product_number}
						</InfoRow>

						<InfoRow label="Product description">
							{equipment.product.description}
						</InfoRow>

					</div>

					<div className="current-equipment-column">
						<InfoRow label="Start date">
							{equipment.date_begin}
						</InfoRow>

						<InfoRow label="End date">
							{equipment.date_end}
						</InfoRow>
						
						<InfoRow label="Status">
							<StatusItem
								is_active={ equipment.is_active }
								is_pending={ equipment.is_pending }
								withLabel
							/>
						</InfoRow>

						<InfoRow label="Support type">
							<Tooltip title={ equipment.sla_ci.name }>
								<Tag
									style={ {
										background: equipment.sla_ci.marker_bg_color,
										color: equipment.sla_ci.marker_text_color,
									} }
								>
									{equipment.sla_ci.marker}
								</Tag>
							</Tooltip>
						</InfoRow>
					</div>
				</div>

				<div className="current-equipment-group">
					<CustomerSection
						label="Customer"
						hasAccount={ equipment.customer.has_account }
						value={ equipment.customer.official_name }
					/>

					<CustomerSection
						label="Subcontractor"
						hasAccount={ equipment.subcontractor.has_account }
						value={ equipment.subcontractor.official_name }
					/>
				</div>
			</Section>

			<Tabs
				items={ tabs }
				activeKey={ activeTab }
				onChange={ onTabChange }
			/>

			{activeTab === "cases" && (
				<EquipmentCases cases={ cases } />
			)}

			{activeTab === "contracts" && (
				<EquipmentContracts contracts={ contracts } />
			)}
		</div>
	);
};

export default CurrentEquipmentPage;
